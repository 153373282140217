import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { Store, select } from "@ngrx/store";
import * as fromTasks from "../../../../home/tasks/store";
import * as Tasks from '../../../../home/tasks/store/actions';
import { LoggingService } from "../../../logging.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as svg from "../../../../shared/svg.inject";
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Observable, Subscription } from 'rxjs';
import { Comment } from 'src/app/shared/models/interfaces';
import { tap } from 'rxjs/operators';


@Component({
  selector: "TasksLine",
  templateUrl: './tasks-line.component.html',
  styleUrls: ['./tasks-line.component.scss'],
})
export class TasksLine implements OnChanges, AfterViewInit {
  @Input() task: any;
  @Input() editTask: any;
  @Input() role: string;
  @ViewChild('taskNotes', { static: false }) taskNotes!: ElementRef;
  @ViewChild('taskComments', { static: false }) taskComments!: ElementRef;

  public deleteTask: boolean = false;
  public showAddCommentArea: boolean = false;
  public activeTab: string = 'details';

  public comments: Comment[] = [];
  public comments$: Observable<Comment[]>;
  private commentSubscription: Subscription;
  public commentForm: FormGroup;
  public commentError: boolean = false;
  public errorMessage: string = "";
  public errorMessage$: Observable<string>;
  public successMessage: string = "";
  public successMessage$: Observable<string>;

  private defaultMessage: string = "Refer to a video time (mm:ss) to add a timestamp";
  public infoMessage: string = this.defaultMessage;
  public isSuccessMessage: boolean = false;

  private parentHeight: number = 0;

  constructor(
    private store: Store<fromTasks.State>,
    private log: LoggingService,
    private fb: FormBuilder,
  ) {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });

    // Binding methods
    this.hideDeleteTask = this.hideDeleteTask.bind(this);
    this.approveTask = this.approveTask.bind(this);
    this.rejectTask = this.rejectTask.bind(this);
    this.openTask = this.openTask.bind(this);
    this.doDeleteTask = this.doDeleteTask.bind(this);
    this.showDeleteTask = this.showDeleteTask.bind(this);
    this.showAddComment = this.showAddComment.bind(this);
    this.addComment = this.addComment.bind(this);
    this.formatDate = this.formatDate.bind(this);

    this.comments$ = new Observable<Comment[]>();
    this.successMessage$ = store.select(state => state.tasks.tasks.successmessage);
    this.errorMessage$ = store.select(state => state.tasks.tasks.errormessage);
    
    this.commentSubscription = new Subscription();


  }

  ngOnInit() {
    this.loadComments();

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.task){
      this.task = changes.task.currentValue;
    }
  }

  private loadComments(): void {
    if (this.task && this.task.id) {
      if (this.commentSubscription) {
        this.commentSubscription.unsubscribe();
      }

      this.comments$ = this.store.pipe(
        select(fromTasks.getTaskComments(this.task.id)),
        tap(comments => {
          this.comments = comments;
        })
      );

      this.commentSubscription = this.comments$.subscribe();
      if (this.comments && this.comments.length < 2) {
        this.showAddCommentArea = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.commentSubscription) {
      this.commentSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    svg.inject();
  }

  doDeleteTask() {
    this.deleteTask = !this.deleteTask;
    this.log.Action("delete-task", { taskId: this.task.id });
    this.store.dispatch(new Tasks.DeleteTask(this.task.id));
  }

  approveTask() {
    this.log.Action("update-task-status", { status: "approved" });
    this.store.dispatch(new Tasks.EditTask(this.task.id, {
      id: this.task.id,
      status: "approved",
    }));
  }

  rejectTask() {
    this.log.Action("update-task-status", { status: "rejected" });
    this.store.dispatch(new Tasks.EditTask(this.task.id, {
      id: this.task.id,
      status: "rejected",
    }));
  }

  showDeleteTask() {
    this.deleteTask = true;
  }

  hideDeleteTask() {
    this.deleteTask = false;
  }

  showAddComment() {
    this.showAddCommentArea = !this.showAddCommentArea;
    if (this.showAddCommentArea) {
      const taskComments = this.taskComments.nativeElement;
      taskComments.scrollTop = 0;
    }
  }

  openTask() {
    this.log.Action("update-task-status", { status: "open" });
    this.store.dispatch(new Tasks.EditTask(this.task.id, {
      id: this.task.id,
      status: "open",
    }));
  }

  truncate(fullStr: string, strLen: number, separator: string) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars);
  }

  shortenString(text: string, length: number): string {
    const trimmedString = text.length > length ? text.substring(0, length - 3) : text;
    return trimmedString;
  }

  formatDate(timestamp: number): string {
    const now = Date.now();
    const timeDiff = now - timestamp * 1000;

    const minutes = Math.floor(timeDiff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    return 'Just now';
  }

  addComment() {
    // scroll to top of task comments
    const taskComments = this.taskComments.nativeElement;
    taskComments.scrollTop = 0;

    if (!this.commentForm.valid) {
      if (!this.commentForm.value.comment) {
        this.commentError = true;
        this.errorMessage = "Please enter a comment before submitting";
      }
      return;
    }

    const comment = {
      Content: this.commentForm.value.comment,
    }

    this.store.dispatch(new Tasks.AddComment(this.task.id, comment));

    this.commentForm.reset();
    this.commentError = false;
    this.errorMessage = "";
    this.loadComments();

    this.infoMessage = "Comment submitted successfully!";
    this.isSuccessMessage = true;

    setTimeout(() => {
      this.infoMessage = this.defaultMessage;
      this.isSuccessMessage = false;
    }, 5000);
  }
}