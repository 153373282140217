import {Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import * as fromDevices from '../../../../../home/devices/store';
import {
  MqttService,
} from '../../../../mqtt.service';
import {kerberosConfig} from "../../../../../../environments/environment";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { VideoJsComponent } from '../../../video-js/video-js.component';

@Component({
  selector: "WebRTCStream",
  templateUrl: './webrtcstream.component.html',
  styleUrls: ['./webrtcstream.component.scss'],
})
export class WebRTCStream implements OnInit, OnDestroy  {

  @Input() deviceKey: string;
  @Input() cloudKey: string;
  @Input() changeStatus: any;
  @Input() encrypted: boolean = false;
  @Input() profile: any = null;
  @ViewChild('target', { static: false }) target: VideoJsComponent;

  public config: any;
  public turnServer: any = kerberosConfig.turnServer;
  public pc: any;
  public cuuid: any;
  public candidatesQueue: any;

  public subscription: any = null;
  public subscriptionICE: any = null;
  public sendChannel: any;
  public clearInterval: any;

  public status: string = 'pending';
  public livestream;
  public liveStreamObservable;
  public liveLegacyStreamObservable;
  public pollingObservables: Array<any> = [];
  public livestreamStarted: boolean = false;

  public eventsSubscription: Subscription;
  public events$: any;
  public events: any;

  constructor(
    private store: Store<fromDevices.State>,
    private mqttService: MqttService) {}

    ngOnInit(){
      this.updateStatus('pending');
      this.handleNegotiationNeededEvent = this.handleNegotiationNeededEvent.bind(this);
      this.mute = this.mute.bind(this);
    }

    ngAfterViewInit(){
      this.startStream();
    }

    updateStatus(status) {
      this.status = status;
      this.changeStatus(status);
    }

    startStream() {

      this.cuuid = uuidv4();
      this.candidatesQueue = [];

      const stunServer = "stun:"+ this.turnServer.split("turn:")[1];
      this.config = {
        //iceTransportPolicy: 'relay',
        iceServers: [
          {
            urls: [stunServer],
          },
          {
            urls: [this.turnServer],
            username: kerberosConfig.turnUsername,
            credential: kerberosConfig.turnPassword,
          }
        ],
      };

      // Setup WebRTC component.
      this.pc = new RTCPeerConnection(this.config);

      this.pc.onnegotiationneeded = this.handleNegotiationNeededEvent;

      this.pc.onicecandidateerror = (event) => {
        console.log("ICE Candidate Error", event);
      }


      this.pc.oniceconnectionstatechange = e => {
        this.status = this.pc.connectionState;
      }

      this.pc.onconnectionstatechange = e => {
        this.status = this.pc.connectionState;
        if(this.status === 'failed') {
          // While we are disconnected we should try to reconnect
          this.pc.restartIce();
        }
      }

      this.pc.ontrack = (event) => {
        this.target.setWebRTCStream(event.streams[0]);
      };

      this.events$ = this.store.select(fromDevices.getEventsFromDevice(this.deviceKey));
      this.eventsSubscription = this.events$.subscribe((event) => {
          // Filter on the id of the device
          if (event && event.action === 'receive-hd-answer' && this.pc) {
            // Make sure we are working on the correct session.
            const session_id = event.value.session_id;
            if(session_id && session_id !== this.cuuid) {
              return;
            }
            const answer = event.value.sdp.toString();
            // Convert from base64
            const answerString = atob(atob(answer));
            this.pc.setRemoteDescription(
              new RTCSessionDescription({
                type: "answer",
                sdp: answerString,
              })
            ).then(() => {
              for(let i = 0; i < this.candidatesQueue.length; i++){
                this.pc.addIceCandidate(this.candidatesQueue[i]);
              }
            });
          } else if(event && event.action === 'receive-hd-candidates' && this.pc){
            // Make sure we are working on the correct session.
            const session_id = event.value.session_id;
            if(session_id && session_id !== this.cuuid) {
              return;
            }
            const candidate = JSON.parse(event.value.candidate.toString());
            if(this.pc.currentRemoteDescription) {
              this.pc.addIceCandidate(candidate);
              console.log("Adding candidate", candidate);
            } else {
              this.candidatesQueue.push(candidate);
            }
          }

      });

      this.pc.onicecandidate = (event) => {
        if (event.candidate) {
          // New Method single observable
          // We should get rid of the legacy methods as mentioned below.
          const topic = "kerberos/agent/" + this.cloudKey;
          const payload = {
            action: "receive-hd-candidates",
            device_id: this.deviceKey,
            value: {
              timestamp: Math.floor(Date.now() / 1000),
              session_id: this.cuuid,
              candidate: event.candidate.candidate,
            }
          };
          this.mqttService.publish(topic, payload, this.encrypted)
        } else {
          // All ICE candidates have been sent
        }
      }

      // This will fire the whole process!
      this.pc.addTransceiver("video", {
        direction: "sendrecv",
      });
      this.pc.addTransceiver("audio", {
        direction: "sendrecv",
      });
    }

    retry(){
      this.pc.restartIce();
    }

    mute(isMuted: boolean) {
      const video = this.target;
      if(isMuted) {
        video.setMute(true);
        video.setVolume(0);
      } else {
        video.setMute(false);
        video.setVolume(1);
      }
    }

    handleNegotiationNeededEvent() {
      this.candidatesQueue = [];
      return this.pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      }).then(offer => {
        return this.pc.setLocalDescription(offer);
      }).then(() => {
        // New Method single observable
        // We should get rid of the legacy methods as mentioned below.
        const topic = "kerberos/agent/" + this.cloudKey;
        const payload = {
          action: "request-hd-stream",
          device_id: this.deviceKey,
          value: {
            timestamp: Math.floor(Date.now() / 1000),
            session_id: this.cuuid,
            session_description: btoa(this.pc.localDescription.sdp),
          }
        };
        this.mqttService.publish(topic, payload, this.encrypted)


      }).catch(error => console.log(error));
    }

    disconnectStream() {
      if(this.clearInterval) {
        clearInterval(this.clearInterval);
      }
      if(this.sendChannel){
        this.sendChannel.close();
      }
      if(this.pc){
        this.pc.close();
        this.pc = null;
      }
      if(this.subscription) {
        this.subscription.unsubscribe();
      }
      if(this.subscriptionICE) {
        this.subscriptionICE.unsubscribe();
      }
      if (this.eventsSubscription) {
        this.eventsSubscription.unsubscribe();
      }
      this.updateStatus('checking');
    }

    ngOnDestroy() {
      this.disconnectStream();
    }
}
