import {Component, OnInit, Input, Inject} from '@angular/core';
import { MqttService as MqttService2} from "ngx-mqtt";
import {
  MqttService,
} from '../../../../mqtt.service';

@Component({
  selector: "PTZ",
  templateUrl: './ptz.component.html',
  styleUrls: ['./ptz.component.scss'],
})
export class PTZ implements OnInit  {

  @Input() hubKey: string;
  @Input() deviceKey: string;
  @Input() onvif: boolean = false;
  @Input() zoom: boolean = false;
  @Input() pantilt: boolean = false;
  @Input() encrypted: boolean = false;

  constructor(
    private mqttService: MqttService,
    @Inject('mqttNew') private _mqttService: MqttService2) {}

  ngOnInit(){
    this.changeDirection = this.changeDirection.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
  }

  changeDirection(value){
    let up = 0;
    let down = 0;
    let right = 0;
    let left = 0;
    let center = 0;

    switch (value){
      case "up":
        up = 1;
        break;
      case "down":
        down = 1;
        break;
      case "left":
        left = 1;
        break;
      case "right":
        right = 1;
        break;
      case "center":
        center = 1;
        break;
    }
    const pantilt = {
      action: "ptz",
      payload: {
        up,
        down,
        left,
        right,
        center
      }
    };

    const topic = "kerberos/agent/" + this.hubKey;
    const payload = {
      action: "navigate-ptz",
      device_id: this.deviceKey,
      value: {
        timestamp: Math.floor(Date.now() / 1000),
        action: JSON.stringify(pantilt)
      }
    };
    this.mqttService.publish(topic, payload, this.encrypted)

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // TODO GET RID OF IT, but we can't do it now because we have to support old agents
    // Legacy
    this._mqttService.unsafePublish(`kerberos/onvif/${this.deviceKey}`, JSON.stringify(pantilt), {qos: 2});
  }

  changeZoom(value){
    const zoom = {
      action: "zoom",
      payload: {
        zoom: value
      }
    };

    const topic = "kerberos/agent/" + this.hubKey;
    const payload = {
      action: "navigate-ptz",
      device_id: this.deviceKey,
      value: {
        timestamp: Math.floor(Date.now() / 1000),
        action: JSON.stringify(zoom)
      }
    };
    this.mqttService.publish(topic, payload, this.encrypted)

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // TODO GET RID OF IT, but we can't do it now because we have to support old agents
    // Legacy
    this._mqttService.unsafePublish(`kerberos/onvif/${this.deviceKey}`, JSON.stringify(zoom), {qos: 2});
  }
}
