// click-outside.directive.ts
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnDestroy, AfterViewInit {
  @Output() clickOutside = new EventEmitter<void>();

  documentClickSubscription: Subscription | undefined;

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit(): void {
    this.documentClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event) => {
          return !this.isInside(event.target as HTMLElement);
        })
      ).subscribe(() => { this.clickOutside.emit(); });
  }

  isInside(elementToCheck: HTMLElement): boolean {
    return ( 
      elementToCheck === this.elementRef.nativeElement || 
      this.elementRef.nativeElement.contains(elementToCheck)
    );
  }

  ngOnDestroy(): void {
    if (this.documentClickSubscription) {
      this.documentClickSubscription.unsubscribe();
    }
  }
}