import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Label } from '../../models/interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LabelRowComponent } from './label-row/label-row.component';

@Component({
  selector: 'LabelsManager',
  templateUrl: './labels-manager.component.html',
  styleUrls: ['./labels-manager.component.scss']
})
export class LabelsManagerComponent implements OnInit, OnChanges {
  @ViewChildren('labelRow') labelRows: QueryList<LabelRowComponent> | undefined;

  @Input() labels: Label[];
  @Input() error: string = '';
  @Input() loading: boolean = false;

  @Output() updateLabel = new EventEmitter<Label>();
  @Output() deleteLabel = new EventEmitter<String>();

  addForm: FormGroup;
  editForm: FormGroup;
  
  constructor() {

  }

  ngOnInit(): void{
  }

  closeAllPopovers(id: string): void {
    if (this.labelRows) {
      this.labelRows.forEach(labelRow => {
        if(labelRow.label.id !== id){
          labelRow.showControls = false
        }
      });
    }
  }

  onUpdateLabel(label: Label): void {
    this.updateLabel.emit(label);
  }

  onDeleteLabel(label: Label): void {
    this.deleteLabel.emit(label.id);
  }

  onEdit(label: Label): void {
    this.editForm.setValue({
      name: label.name,
      description: label.description,
      color: label.color
    });
  }

  onCancelEdit(): void {
    this.editForm.reset({ color: '#000000', name: '', description: '' });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
