import { Action } from '@ngrx/store';

export const RESET = '[Tasks] Reset';
export const REQUEST_TASKS = '[Tasks] Requesting tasks';
export const REQUEST_TASKS_SUCCESS = '[Tasks] Requesting tasks Success';
export const REQUEST_TASKS_FAILURE = '[Tasks] Requesting tasks Failure';
export const REQUEST_FILTERED_TASKS = '[Tasks] Requesting filtered tasks';
export const REQUEST_FILTERED_TASKS_SUCCESS = '[Tasks] Requesting filtered tasks Success';
export const REQUEST_FILTERED_TASKS_FAILURE = '[Tasks] Requesting filtered tasks Failure';
export const GET_TASKS_STATISTICS = '[Tasks] Get tasks statistics';
export const GET_TASKS_STATISTICS_SUCCESS = '[Tasks] Get tasks statistics Success';
export const GET_TASKS_STATISTICS_FAILURE = '[Tasks] Get tasks statistics Failure';
export const ADD_TASK = '[Tasks] Add task';
export const ADD_TASK_SUCCESS = '[Tasks] Add task Success';
export const ADD_TASK_FAILURE = '[Tasks] Add task Failure';
export const EDIT_TASK = '[Tasks] Edit task';
export const EDIT_TASK_SUCCESS = '[Tasks] Edit task Success';
export const EDIT_TASK_FAILURE = '[Tasks] Edit task Failure';
export const DELETE_TASK = '[Tasks] Delete task';
export const DELETE_TASK_SUCCESS = '[Tasks] Delete task Success';
export const DELETE_TASK_FAILURE = '[Tasks] Delete task Failure';
export const RESET_MESSAGE = '[Tasks] Hide success';
export const GET_COMMENTS = '[Tasks] Get comments';
export const GET_COMMENTS_SUCCESS = '[Tasks] Get comments Success';
export const GET_COMMENTS_FAILURE = '[Tasks] Get comments Failure';
export const ADD_COMMENT = '[Tasks] Add comment';
export const ADD_COMMENT_SUCCESS = '[Tasks] Add comment Success';
export const ADD_COMMENT_FAILURE = '[Tasks] Add comment Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor() {}
}

export class RequestTasks implements Action {
  readonly type = REQUEST_TASKS;

  constructor() {}
}

export class RequestTasksSuccess implements Action {
  readonly type = REQUEST_TASKS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestTasksFailure implements Action {
  readonly type = REQUEST_TASKS_FAILURE;

  constructor(public payload?: any) {}
}

export class RequestFilteredTasks implements Action {
  readonly type = REQUEST_FILTERED_TASKS;

  constructor(public payload: any) {}
}

export class RequestFilteredSuccess implements Action {
  readonly type = REQUEST_FILTERED_TASKS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestFilteredFailure implements Action {
  readonly type = REQUEST_FILTERED_TASKS_FAILURE;

  constructor(public payload?: any) {}
}

export class GetTasksStatistics implements Action {
  readonly type = GET_TASKS_STATISTICS;

  constructor() {}
}

export class GetTasksStatisticsSuccess implements Action {
  readonly type = GET_TASKS_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetTasksStatisticsFailure implements Action {
  readonly type = GET_TASKS_STATISTICS_FAILURE;

  constructor(public payload?: any) {}
}

export class AddTask implements Action {
  readonly type = ADD_TASK;

  constructor(public payload: any) {}
}

export class AddTaskSuccess implements Action {
  readonly type = ADD_TASK_SUCCESS;
  constructor(public payload: any) {}
}

export class AddTaskFailure implements Action {
  readonly type = ADD_TASK_FAILURE;

  constructor(public payload?: any) {}
}

export class EditTask implements Action {
  readonly type = EDIT_TASK;

  constructor(public id: any, public payload: any) {}
}

export class EditTaskSuccess implements Action {
  readonly type = EDIT_TASK_SUCCESS;

  constructor(public payload: any) {}
}

export class EditTaskFailure implements Action {
  readonly type = EDIT_TASK_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteTask implements Action {
  readonly type = DELETE_TASK;

  constructor(public id: any) {}
}

export class DeleteTaskSuccess implements Action {
  readonly type = DELETE_TASK_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteTaskFailure implements Action {
  readonly type = DELETE_TASK_FAILURE;

  constructor(public payload?: any) {}
}

export class GetComments implements Action {
  readonly type = GET_COMMENTS;

  constructor(public id: any) {}
}

export class GetCommentsSuccess implements Action {
  readonly type = GET_COMMENTS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetCommentsFailure implements Action {
  readonly type = GET_COMMENTS_FAILURE;

  constructor(public payload?: any) {}
}

export class AddComment implements Action {
  readonly type = ADD_COMMENT;

  constructor(public taskId: any, public payload: any) {}
}

export class AddCommentSuccess implements Action {
  readonly type = ADD_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class AddCommentFailure implements Action {
  readonly type = ADD_COMMENT_FAILURE;

  constructor(public payload?: any) {}
}


export type Actions =
  | Reset
  | ResetMessage
  | RequestTasks
  | RequestTasksSuccess
  | RequestTasksFailure
  | RequestFilteredTasks
  | RequestFilteredFailure
  | RequestFilteredSuccess
  | GetTasksStatistics
  | GetTasksStatisticsSuccess
  | GetTasksStatisticsFailure
  | AddTask
  | AddTaskSuccess
  | AddTaskFailure
  | EditTask
  | EditTaskSuccess
  | EditTaskFailure
  | DeleteTask
  | DeleteTaskSuccess
  | DeleteTaskFailure
  | GetComments
  | GetCommentsSuccess
  | GetCommentsFailure
  | AddComment
  | AddCommentSuccess
  | AddCommentFailure;
  
