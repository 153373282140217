import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {environment, kerberosConfig} from '../../environments/environment';
import { patternValidator } from '../shared/pattern.validator';
import { Store } from '@ngrx/store';
import * as fromAuth from './store';
import * as Auth from './store/actions';

@Component({
  templateUrl: './signup.component.html',
  styleUrls: ['./login.component.scss'],
})
export class SignupComponent {

  public registered$ = this.store.select(fromAuth.getRegistered);
  public registered: any;
  public registeredSubscription: any;
  public registeredError$ = this.store.select(fromAuth.getRegisteredError);
  public registeredError: any;
  public registeredErrorSubscription: any;
  public registerPending$ = this.store.select(fromAuth.getRegisterPending);
  public registerPending: any;
  public showTermsOfUse: boolean = false;
  public isDemo = environment.demo;
  public tos: boolean = false;

  public registerForm: FormGroup;
  public tosForm: FormGroup;
  public domain: string = "";
  public config: any;

  constructor(private router: Router,
              private builder: FormBuilder,
              private store: Store<fromAuth.State>) {

    this.config = kerberosConfig;

    if(this.config.multiTenant) {
      const currentUrl = window.location.href; // "https://miniso.kerberos.io/login"; //
      const currentUrlParts = currentUrl.split("/");
      if(currentUrlParts.length >= 3) {
        const hostname = currentUrlParts[2];
        if(hostname !== "") {
          // Get rid of the base domain, so we have the "subdomain".
          this.domain = hostname.replace(this.config.tenantBaseDomain, "");
          // we will also remove the "." in front of the base domain.
          this.domain = this.Trim(this.domain, ".");
        }
      }
    }


    this.registerForm = builder.group({
      "username": ["", [Validators.required, patternValidator(/^[a-z0-9-_]+$/)]],
      "email": ["", [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      'passwordGroup': builder.group({
        "password": ['', Validators.required],
        "repeat": ['', Validators.required]
      }, {validator: [this.passwordValidator, this.areEqual]}),
    });

    this.tosForm = builder.group({
      "tos": [false, Validators.required],
    });

    this.registeredErrorSubscription = this.registeredError$.subscribe(error => {
      this.registeredError = error;
    });

    this.registeredSubscription = this.registered$.subscribe(registered => {
      this.registered = registered;
    });

    this.store.dispatch(new Auth.ResetMFA());
  }

  public onRegistration($event) : void {
    const values = this.registerForm.value;
    this.store.dispatch(new Auth.Register({
      username: values.username,
      email: values.email,
      password: values.passwordGroup.password,
      retry: values.passwordGroup.retry,
      domain: this.domain,
    }));
  }

  public areEqual(group: FormGroup) {
    var valid = false;

    let value = "";
    for (let name in group.controls) {
      var val = group.controls[name].value
      if(value != "") {
        if(value == val) {
          valid = true;
        }
        else {
          break;
        }
      }
      value = val;
    }

    if (valid) {
      return null;
    }

    return {
      areNotEqual: true
    };
  }

  private passwordValidator(group: FormGroup) {
    let password = group.controls['password'];
    let repeat = group.controls['repeat'];

    if(password.value.length < 8) {
      return {
        passwordTooShort: true
      };
    }

    if(password.value.length > 72) {
      return {
        passwordTooLong: true
      };
    }

    return null;
  }

  private Trim(str, ch) {
    var start = 0,
        end = str.length;
    while(start < end && str[start] === ch)
        ++start;
    while(end > start && str[end - 1] === ch)
        --end;
    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
  }


  public acceptTermsOfUse() {
    this.tosForm.setValue({tos: true});
  }

  public openTermsOfUse() {
    this.showTermsOfUse = true;
  }

  public closeTermsOfUse() {
    this.showTermsOfUse = false;
  }

  public closeRegisteredError() {
    this.registeredError = null;
  }

  public closeRegistrationForm() {
    this.registered = null;
  }

  public ngOnDestroy() {
    this.registeredErrorSubscription.unsubscribe();
    this.registeredSubscription.unsubscribe();
  }
}
