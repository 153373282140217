import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import * as MediaAction from '../../media/store/actions';
import { TasksService } from '../tasks.service';

@Injectable()
export class Effects {

    /* Retrieve all tasks */

    @Effect()
    requestTasks: Observable<Action.RequestTasksFailure | Action.RequestTasksSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_TASKS),
        switchMap((action: Action.RequestTasks) => {
          return this.tasksService.getTasks().pipe(map((tasks) => {
            return new Action.RequestTasksSuccess(tasks);
          }),
          catchError(e => {
            return observableOf(new Action.RequestTasksFailure(e));
          }),);
        }));

    /* Retrieve filtered tasks */

    @Effect()
    requestFilteredTasks: Observable<Action.RequestFilteredFailure | Action.RequestFilteredSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_FILTERED_TASKS),
        switchMap((action: Action.RequestFilteredTasks) => {
          return this.tasksService.getFilteredTasks(action.payload).pipe(map((tasks) => {
              return new Action.RequestFilteredSuccess(tasks);
            }),
            catchError(e => {
              return observableOf(new Action.RequestFilteredFailure(e));
            }),);
        }));

    /* Get tasks statistics */

    @Effect()
    getTasksStatistics: Observable<Action.GetTasksStatisticsFailure | Action.GetTasksStatisticsSuccess> = this.actions$.pipe(
        ofType(Action.GET_TASKS_STATISTICS),
        switchMap((action: Action.RequestTasks) => {
          return this.tasksService.getTasksStatistics().pipe(map((tasks) => {
              return new Action.GetTasksStatisticsSuccess(tasks.data);
            }),
            catchError(e => {
              return observableOf(new Action.GetTasksStatisticsFailure(e));
            }),);
        }));

    /* Add task */
    @Effect()
    addTask: Observable<Action.AddTaskFailure | Action.AddTaskSuccess> = this.actions$.pipe(
        ofType(Action.ADD_TASK),
        switchMap((action: Action.AddTask) => {
          return this.tasksService.addTask(action.payload).pipe(map((task) => {
            return new Action.AddTaskSuccess(task);
          }),
          catchError(e => {
            return observableOf(new Action.AddTaskFailure(e));
          }),);
        }));

    /* Edit task */

    @Effect()
    editTask: Observable<Action.EditTaskFailure | Action.EditTaskSuccess> = this.actions$.pipe(
        ofType(Action.EDIT_TASK),
        switchMap((action: Action.EditTask) => {
          return this.tasksService.editTask(action.id, action.payload).pipe(map((task) => {
            return new Action.EditTaskSuccess(task.data);
          }),
          catchError(e => {
            return observableOf(new Action.EditTaskFailure(e));
          }),);
        }));

    /* Delete task */

    @Effect()
    deleteTask: Observable<Action.DeleteTaskFailure | Action.DeleteTaskSuccess> = this.actions$.pipe(
        ofType(Action.DELETE_TASK),
        switchMap((action: Action.DeleteTask) => {
          return this.tasksService.deleteTask(action.id).pipe(map((tasks) => {
            return new Action.DeleteTaskSuccess(tasks.data);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteTaskFailure(e));
          }),);
        }));

    /* Get comments */

    @Effect()
    getComments: Observable<Action.GetCommentsFailure | Action.GetCommentsSuccess> = this.actions$.pipe(
        ofType(Action.GET_COMMENTS),
        switchMap((action: Action.GetComments) => {
          return this.tasksService.getComments(action.id).pipe(map((comments) => {
            return new Action.GetCommentsSuccess(comments.data);
          }),
          catchError(e => {
            return observableOf(new Action.GetCommentsFailure(e));
          }),);
        }));

    /* Add comment */

    @Effect()
    addComment: Observable<Action.AddCommentFailure | Action.AddCommentSuccess> = this.actions$.pipe(
      ofType(Action.ADD_COMMENT),
      switchMap((action: Action.AddComment) => {
        return this.tasksService.addComment(action.taskId, action.payload) // taskId and comment are passed separately here
          .pipe(
            map((comment) => new Action.AddCommentSuccess(comment)),
            catchError(e => observableOf(new Action.AddCommentFailure(e)))
          );
      })
    );
    

    constructor(private tasksService: TasksService,
                private actions$: Actions) {}
}
