import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import {Md5} from "ts-md5";

@Component({
  selector: "AccountsPicker",
  templateUrl: './accountspicker.component.html',
  styleUrls: ['./accountspicker.component.scss'],
})
export class AccountsPicker implements OnChanges  {

  @Input() icon: string;
  @Input() text: string;
  @Input() items: any; // [{text: ..., value: ...}, ...s]
  @Input() selected: string[] = [];
  @Input() placeholder: string = "Search..";
  @Input() onChange: any;
  @Input() error: boolean = false;

  public open: boolean = false;
  public search: string = "";
  public filteredItems: any[] = [];
  public selectedItems: string[] = [];
  public selectedValues: string[] = [];
  public gravatarSize: number = 40;

  constructor() {
    this.changeSelect = this.changeSelect.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onChangeAccounts = this.onChangeAccounts.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.items){
      this.filteredItems = this.filterByName(changes.items.currentValue, this.search);
    }
    if(changes.selected && changes.selected.currentValue) {
      this.selectedItems = changes.selected.currentValue.slice();
    }
  }

  filterByName(items, search){
    if(search == "") {
      return items;
    }
    return items.filter(i => i.text.includes(search) || i.text.toLowerCase().includes(search.toLowerCase()));
  }

  changeSearch(event) {
    const { value } = event.target;
    this.search = value;
    this.filteredItems = this.filterByName(this.items, this.search);
  }

  isSelected(value){
    if(!this.selectedItems){
      return false;
    }
    return this.selectedItems.filter(s => s === value).length > 0;
  }

  changeSelect(value, event) {
    const { checked } = event.target;
    if (checked) {
      // add it
      this.selectedItems.push(value);
      // make unique
      this.selectedItems = this.selectedItems.filter((item, i, ar) => ar.indexOf(item) === i);
    } else {
      //remove it
      this.removeOccurrences(this.selectedItems, value);
    }
    // Reiterate over selectedValues.
    this.selectedValues = this.selectedItems.map(i => {
      const item = this.items.find(i2 => i2.value === i)
      if(item){
        return item.text;
      }
    })
    this.onChange(this.selectedItems, this.selectedValues);
  }

  toggleDropdown(){
    this.open = !this.open;
  }

  identify(index, item){
    return item.value;
  }

  removeOccurrences(array, item) {
    var i = array.length;
    while (i--) {
      if (array[i] === item) {
        array.splice(array.indexOf(item), 1);
      }
    }
  }

  public onChangeAccounts(accounts){
  }

  public getGravatar(email) {
    return 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?s=' + this.gravatarSize + '&d=mm';
  }
}
