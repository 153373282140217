import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class TasksService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getTasks() : Observable<any> {
    return this.http.get(this.apiService.getTasks()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getFilteredTasks(payload) : Observable<any> {
    return this.http.post(this.apiService.getFilteredTasks(), payload).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getTasksStatistics() : Observable<any> {
    return this.http.get(this.apiService.getTasksStatistics()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addTask(task) : Observable<any> {
    return this.http.post(this.apiService.addTask(), { task }).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public editTask(id, taskUpdates) : Observable<any> {
    return this.http.patch(this.apiService.editTask(id),taskUpdates).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public deleteTask(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteTask(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public getComments(id) : Observable<any> {
    return this.http.get(this.apiService.getComments(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addComment(id, comment) : Observable<any> {
    return this.http.post(this.apiService.addComment(id), { comment }).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
